<script setup>
import {useAuthStore} from "@/stores/auth.js";
import AppBackground from "@/components/AppBackground.vue";
import AppButton from "@/components/AppButton.vue";
import TheRoom from "@/components/TheRoom.vue";
import TheList from "@/components/TheList.vue";
import router from "@/router/index.js";
import {computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref, watch} from "vue";
import AppPopup from "@/components/AppPopup.vue";
import TheResetPopup from "@/components/TheResetPopup.vue";
import {useSelectedGame} from "@/stores/game.js";
import {useGlobalPopupStore} from "@/stores/popup.js";
import {usePreloaderStore} from "@/stores/preloader.js";
import axiosInstance from "@/api.js";
import AppLoader from "@/components/AppLoader.vue";
import {getLocalData, openWindow, removeLocalData, setLocalData} from "@/plugins/functions.js";
import {useWindowFocus} from "@vueuse/core";
import AppLightGallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';
import {useStaticPagesStore} from "@/stores/static.js";
import TheBanner from "@/components/Home/TheBanner.vue";
import {useI18n} from "vue-i18n";
import {useStaticData} from "@/stores/staticData.js";

const {t, locale} = useI18n({useScope: 'global'})

const pluginsLightGallery = ref([lgZoom])

const authStore = useAuthStore()
const selectedGame = useSelectedGame()
const globalPopup = useGlobalPopupStore()
const globalPreloader = usePreloaderStore()
const staticData = useStaticData()

const activeGames = ref([])
const imageName = ref('')

const isOpenHowToPlay = ref(false)

const showPopup = ref(false)
const roomData = ref([])
const loadingMyGame = ref(true)
const loadingAllGames = ref(true)
let updateInterval = null
const windowFocus = useWindowFocus()

const timeToUpdateGamesMS = 1000 * 60
const interfaceScreenshotPath = computed(() => '/interface/' + locale.value + '/')


onBeforeMount(() => {
  let params = router.currentRoute.value.query
  if (params['connected']
      && (params['connected'] === 'resetPassword' || params['connected'] === 'resetEmail')) {
    showPopup.value = true
  }
})
onMounted(async () => {
  Promise.all([getImageName(), updateAllGames(), updateMyGames()])
      .then()
      .finally(() => {
        staticData.allGamesLoadedBefore = true
      })
  // await getImageName()
  // await updateAllGames()
  // await updateMyGames()

  setIntervalIfPageFocused()
})
onBeforeUnmount(() => {
  clearInterval(updateInterval)
})
onUnmounted(() => {
  clearInterval(updateInterval)
})

watch(windowFocus, () => {
  setIntervalIfPageFocused()
})

function setIntervalIfPageFocused() {
  if (windowFocus.value) {
    updateInterval = setInterval(async () => {
      await updateAllGames()
    }, timeToUpdateGamesMS + 1000)
  } else {
    clearInterval(updateInterval)
  }
}

async function updateAllGames() {
  try {
    let localeActiveGames = getLocalData('activeGames')
    if (localeActiveGames && localeActiveGames.date) {
      if (new Date(localeActiveGames.date) > new Date()) {
        activeGames.value = localeActiveGames.value
        return
      }
    }

    if(!staticData.allGamesLoadedBefore) {
      loadingAllGames.value = true
    }
    let data = await axiosInstance.post('/allGames', {
      noregToken: authStore.getLocalData('noregToken')
    })
    activeGames.value = data.data.reverse()
    setLocalData('activeGames', {value: data.data, date: +new Date() + timeToUpdateGamesMS})
  } catch (e) {
    console.error('Не удалось обновить все игры')
  } finally {
    loadingAllGames.value = false
  }

}

async function updateMyGames() {
  try {
    let localeRoomData = getLocalData('roomData')
    if (localeRoomData && localeRoomData.date) {
      if (new Date(localeRoomData.date) > new Date()) {
        roomData.value = localeRoomData.value
        return
      }
    }

    let data = await axiosInstance.post('/userGames', {
      noregToken: authStore.getLocalData('noregToken')
    })
    loadingMyGame.value = true
    roomData.value = []
    if (data.data) {
      roomData.value = data.data.sort((room1, room2) => {
        return new Date(room2.dataCreate) - new Date(room1.dataCreate)
      })
      setLocalData('roomData', {value: roomData.value, date: +new Date() + 1000 * 3})
    }
  } catch (e) {
    console.error('Не удалось обновить свои игры')
  } finally {
    loadingMyGame.value = false
  }
}

async function generateRoom() {
  globalPreloader.activate('generateRoom')

  let error = await selectedGame.generateGameId();
  if (error) {
    globalPopup.activate(t('home.error.createRoom'), error.data.message, 'red')
  } else {
    clearInterval(updateInterval)
    await router.push(`/game=${selectedGame.gameId}`)
    removeLocalData('roomData')
  }
  globalPreloader.deactivate('generateRoom')
}

async function getImageName() {
  if (useStaticPagesStore().imageName) {
    imageName.value = useStaticPagesStore().imageName
    return
  }

  globalPreloader.activate('getImageName')
  try {
    let response = await axiosInstance.get('/getHomeImageName')
    imageName.value = response.data.imageName
    useStaticPagesStore().imageName = response.data.imageName
  } catch (e) {
    console.log(e)
    imageName.value = ''
  } finally {
    globalPreloader.deactivate('getImageName')
  }

}

/// Функции lightgallery
let lightGallery = null

function onInitGallery(detail) {
  lightGallery = detail.instance;
}

watch(locale, async (a, b) => {
  if (a !== b) {
    await nextTick(() => {
      lightGallery.refresh()
    })
  }
})

</script>

<template>
  <main class="main">
    <div class="welcome" style="margin-top: 75px">
      <AppBackground :catastrophe-img="imageName"></AppBackground>
      <div class="welcome__container">
        <div class="welcome__body">
          <h1 class="welcome__title">{{ $t("home.welcome.title") }}</h1>
          <p class="welcome__subtitle">{{ $t("home.welcome.subtitle") }}</p>
        </div>
      </div>
    </div>
    <div class="room">
      <div class="room__container">
        <h2 class="room__title">{{ $t("home.room.title") }}</h2>
        <div class="room__body">
          <div class="room__create create-room">
            <div class="create-room__body">
              <AppButton @click="generateRoom" class="create-room__btn create" color="gold">
                {{ $t("home.room.createRoom.createButton") }}
              </AppButton>
              <AppButton class="create-room__btn find" color="purple" iconName="discord.svg"
                         @click="openWindow(t('links.discord'))">{{ $t("home.room.createRoom.find") }}
              </AppButton>
              <AppButton class="create-room__btn howToPlay" color="gold" :border="true" @click="isOpenHowToPlay=true">
                {{ $t("home.room.createRoom.howToPlay") }}
              </AppButton>
            </div>
          </div>
          <AppLoader v-if="loadingMyGame"/>
          <div v-else-if="roomData.length" class="room__list list-room">
            <TheRoom
                v-for="room in roomData"
                :key="room.idRoom"
                :gamers-num="room.countPlayers"
                :isStarted="room.isStarted"
                :datetime="new Date(room.dataCreate)"
                :link="room.idRoom"
                :isHost="room.isHost"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="activeGame">
      <div class="activeGame__container">
        <h2 class="activeGame__title">{{ $t("home.activeGame.title") }}</h2>
        <div class="activeGame__body">
          <AppLoader v-if="loadingAllGames"/>
          <TheList v-else :data="activeGames" :title="$t('home.activeGame.listTitle')" class="activeGame__game"/>
          <!--          <TheList :data="streamersData" title="Стримеры онлайн" class="activeGame__game" />-->
        </div>
      </div>
    </div>
    <TheBanner v-if="locale === 'ru'"/>
    <div class="interface">
      <h2 class="interface__title titleH2">{{ $t('home.interface.title') }}</h2>
      <div class="interface__container">
        <AppLightGallery
            :onInit='onInitGallery'
            :settings="{
            speed:350,
            plugins: pluginsLightGallery,
            licenseKey: '7EC452A9-0CFD441C-BD984C7C-17C8456E',
            download: false,
            hideScrollbar: true,
          }"
            class="interface__body"
        >
          <div class="interface__block" :href="interfaceScreenshotPath+'1.png'">
            <img :src="interfaceScreenshotPath+'1.png'" :alt="$t('home.interface.hostWaitingPageTitle')"
                 style="display: none">
            <div class="interface__img">
              <img :src="interfaceScreenshotPath+'1min.jpg'" :alt="$t('home.interface.hostWaitingPageTitle')">
            </div>
            <div class="interface__titleImg">{{ $t('home.interface.hostWaitingPageTitle') }}</div>
          </div>
          <div class="interface__block" :href="interfaceScreenshotPath+'2.png'">
            <img :src="interfaceScreenshotPath+'2.png'" :alt="$t('home.interface.playerWaitingPageTitle')"
                 style="display: none">
            <div class="interface__img">
              <img :src="interfaceScreenshotPath+'2min.jpg'" :alt="$t('home.interface.playerWaitingPageTitle')">
            </div>
            <div class="interface__titleImg">{{ $t('home.interface.playerWaitingPageTitle') }}</div>
          </div>

          <div class="interface__block" :href="interfaceScreenshotPath+'3.png'">
            <img :src="interfaceScreenshotPath+'3min.jpg'" :alt="$t('home.interface.hostGamePageTitle')"
                 style="display: none">
            <div class="interface__img">
              <img :src="interfaceScreenshotPath+'3min.jpg'" :alt="$t('home.interface.hostGamePageTitle')">
            </div>
            <div class="interface__titleImg">{{ $t('home.interface.hostGamePageTitle') }}</div>
          </div>
          <div class="interface__block" :href="interfaceScreenshotPath+'4.png'">
            <img :src="interfaceScreenshotPath+'4min.jpg'" :alt="$t('home.interface.playerGamePageTitle')"
                 style="display: none">
            <div class="interface__img">
              <img :src="interfaceScreenshotPath+'4min.jpg'" :alt="$t('home.interface.playerGamePageTitle')">
            </div>
            <div class="interface__titleImg">{{ $t('home.interface.playerGamePageTitle') }}</div>
          </div>
          <div class="interface__block" :href="interfaceScreenshotPath+'5.png'">
            <img :src="interfaceScreenshotPath+'5min.jpg'" :alt="$t('home.interface.createCustomGamePageTitle')"
                 style="display: none">
            <div class="interface__img">
              <img :src="interfaceScreenshotPath+'5min.jpg'" :alt="$t('home.interface.createCustomGamePageTitle')">
            </div>
            <div class="interface__titleImg">{{ $t('home.interface.createCustomGamePageTitle') }}</div>
          </div>
        </AppLightGallery>
      </div>
    </div>

    <AppPopup v-model="isOpenHowToPlay">
      <template v-slot:title>
        <p v-html="$t('home.howToPlay.title')"></p>
      </template>
      <div v-html="$t('home.howToPlay.text')"></div>
    </AppPopup>
    <teleport to="#app">
      <TheResetPopup v-if="showPopup"/>
    </teleport>

    <div id="captcha-container"></div>
  </main>
</template>

<style lang="scss">
@import "@/assets/scss/style";
@import 'lightgallery/css/lightgallery.css';
@import "lightgallery/scss/lg-zoom";

.main {
  position: relative;
  overflow: hidden;
  z-index: 4 !important;
}

//========================================================================================================================================================
.welcome {
  width: 100vw;
  height: 100vh;
  //width: 100dvw;
  //height: 100dvh;
  position: relative;
  z-index: 2;

  &._game {
    @media (max-width: $mobile) {
      padding-top: 100px;
      padding-bottom: 100px;

      min-height: 100vh;
      min-height: 100dvh;
      height: auto;
    }
  }

  &__container {
    height: 100%;
  }

  &__body {
    position: relative;
    z-index: 4;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__title {
    text-align: center;
    font-family: Scribble Serif;
    font-size: 90px;
    text-transform: uppercase;
    display: inline;

    @media (max-width: $pc) {
      font-size: 80px;
    }
    @media (max-width: $tablet) {
      font-size: 70px;
    }
    @media (max-width: $mobile) {
      font-size: 50px;
    }
    @media (max-width: $mobileSmall) {
      font-size: 40px;
    }
  }

  &__subtitle {
    font-family: Montserrat;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 400;
    text-align: center;
    max-width: 1047px;
    margin: 0 auto;

    @media (max-width: $tablet) {
      font-size: 13px;
    }
    @media (max-width: $mobile) {
      max-width: 90%;
    }
    @media (max-width: $mobileSmall) {
      font-size: 12px;
    }
  }

  &__playVoice {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000080;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 700;
    margin: 15px auto 0 auto;

    span {
      margin-left: 10px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__population {
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 400;

    @media (max-width: $mobileSmall) {
      font-size: 12px;
    }
  }

  &__block {

    audio {
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
    }
  }
}

//========================================================================================================================================================
.room {
  position: relative;
  z-index: 5;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;

  &__container {
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: $pc) {
      margin-bottom: 45px;
      font-size: 29px;
    }
    @media (max-width: $tablet) {
      margin-bottom: 35px;
      font-size: 27px;
    }
    @media (max-width: $mobile) {
      margin-bottom: 25px;
      font-size: 25px;
    }
  }
}

.create-room {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $pc) {
    margin-bottom: 55px;
  }
  @media (max-width: $tablet) {
    margin-bottom: 45px;
  }
  @media (max-width: $mobile) {
    margin-bottom: 35px;
  }
  @media (max-width: $mobileSmall) {
    margin-bottom: 25px;
  }

  &__body {
    display: flex;
    align-items: center;
    gap: 30px;
    font-size: 11px;

    @media (max-width: 840px) {
      flex-direction: column;
      width: 100%;
      gap: 20px;
    }

    input {
      min-width: 250px;

      @media (max-width: 840px) {
        width: 380px;
        min-width: 0;
        text-align: center;
      }

      @media (max-width: 570px) {
        width: 100%;
      }
    }

  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 38px;

    &.create {

    }

    &.find {
      padding: 13px 22px;
    }

    &.howToPlay {

    }

    @media (max-width: 840px) {
      width: 380px;
    }

    @media (max-width: 570px) {
      width: 100%;
    }
  }
}

.list-room {
  margin-bottom: 80px;

  @media (max-width: $mobile) {
    margin-bottom: 75px;
  }
}

//========================================================================================================================================================
.activeGame {
  margin-bottom: 100px;

  &__container {
  }

  &__title {
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: $pc) {
      font-size: 29px;
    }
    @media (max-width: $tablet) {
      font-size: 28px;
      margin-bottom: 45px;
    }
    @media (max-width: $mobile) {
      font-size: 26px;
      margin-bottom: 35px;
    }
    @media (max-width: $mobileSmall) {
      font-size: 25px;
      margin-bottom: 25px;
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;

    @media (max-width: $tablet) {
      gap: 25px;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__game {
    max-width: 510px;
  }
}

//========================================================================================================================================================
.interface {
  position: relative;
  z-index: 10;
  line-height: 1.2;

  &__title {
  }

  &__container {
  }

  &__body {
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr;

    display: flex;
    justify-content: space-evenly;
    align-items: start;
    flex-wrap: wrap;
    gap: 30px;

    @media (max-width: $tablet) {
      //grid-template-columns: 1fr 1fr;
      //grid-template-rows: 1fr 1fr;
      gap: 19px;
    }
  }

  &__block {
    display: grid;
    grid-template-rows: 250px 1fr;
    gap: 30px;
    //justify-content: center;
    flex: 0 1 17%;

    @media (min-width: $tablet) {
      grid-template-rows: 150px 1fr;
    }
    @media (max-width: $tablet) {
      gap: 19px;
      grid-template-rows: auto auto;
      flex: 0 1 30%;
    }
    @media (max-width: $mobile) {
      flex: 0 1 45%;
    }

    &:hover {
      cursor: pointer;

      .interface__img:not(._active) {
        border-color: white;
        transform: scale(1.05);
      }
    }
  }

  &__img {
    border-radius: 9px;
    overflow: hidden;
    transition: transform 0.3s ease, border-color 0.3s ease;
    border: 1px solid #494949;
    position: relative;
    display: flex;
    justify-self: center;

    //4 в ряд
    //@include adaptiveValue("width", 294, 215, 1920, 992);
    //@include adaptiveValue("width", 350, 160, 991, 360);

    //3 в ряд
    //@include adaptiveValue("width", 400, 300, 1920, 992);
    //@include adaptiveValue("width", 350, 160, 991, 360);

    //5 в ряд
    width: 100%;

    @include adaptiveValue("height", 250, 136, 991, 360);

    @media (min-width: $tablet) {
      height: 150px;
    }

    @media (max-width: 360px) {
      height: 136px
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
      overflow: hidden;
    }

    //&._active {
    //  position: fixed;
    //  z-index: 12;
    //  left: 10%;
    //  top: 10%;
    //  width: 80%;
    //  height: 80%;
    //  transition: none;
    //
    //  img {
    //    object-fit: contain;
    //  }
    //
    //  &::after {
    //    content: '';
    //    position: fixed;
    //    width: 100%;
    //    height: 100%;
    //    left: 0;
    //    top: 0;
    //    background: rgba(0, 0, 0, 0.7);
    //    z-index: -1;
    //  }
    //}
  }

  &__titleImg {
    text-align: center;
  }
}

</style>