import { defineStore } from "pinia";
import { ref } from "vue";
import { useMyProfileStore } from "@/stores/profile.js";
import { usePreloaderStore } from "@/stores/preloader.js";
import { useGlobalPopupStore } from "@/stores/popup.js";
import { authSocket } from "@/socket/sockets.js";
import { switchError } from "@/logics/socketLogic.js";
import configI18n from "@/i18n/config.js";
import {useI18n} from "vue-i18n";

export const useAuthSocketStore = defineStore('authSocket', () => {
  const {t} = useI18n({useScope: 'global'})
  
  const connected = ref(false)
  
  const myProfile = useMyProfileStore()
  const globalPreloader = usePreloaderStore()
  const globalPopup = useGlobalPopupStore()
  
  function bindEvents() {
    authSocket.on('setError', async data => {
      await switchError(data, authSocket)
      globalPreloader.deactivate('all')
    })
    authSocket.on('sendMessage', data => {
      const title = configI18n.tData(data.title)
      const message = configI18n.tData(data.message)
      const color = data.color
      globalPopup.activate(title || t('globalPopup.serverMessageDefaultTitle'), message || '', color)
    })
    authSocket.on('sendMessage:timer', data => {
      const title = configI18n.tData(data.title)
      const message = configI18n.tData(data.message)
      const color = data.color
      globalPopup.activate(title || t('globalPopup.serverMessageDefaultTitle'), message || '', color, true)
    })
    
    
    authSocket.on('logout',() => {
      setTimeout(async () => {
        myProfile.clearUserInfo()
        authSocket.close()
        location.reload()
      },1000)
    })
    authSocket.on('disconnect',() => {
      console.log('DISCONNECT AUTH')
    })
    authSocket.on('connect', () => {
      console.log('CONNECT AUTH')
    })
  }
  
  function _connect() {
    authSocket.auth = {
      token: myProfile.token,
      _retry: false,
    }
    authSocket.connect()
    connected.value = true
  }
  
  function close() {
    authSocket.close()
    authSocket.removeAllListeners()
    connected.value = false
  }
  
  function emit(funcName, ...args) {
    authSocket.emit(funcName, ...args)
  }
  
  function on(funcName, functionStr) {
    authSocket.on(funcName, functionStr)
  }
  
  function removeListener(funcName) {
    authSocket.removeListener(funcName)
  }
  
  function setConnect() {
    if (!connected.value && !myProfile.isNoReg) {
      console.log('Соединяем с AUTH SOCKET.IO')
      bindEvents()
      _connect()
    }
  }
  
  return {
    connected,
    bindEvents,
    _connect,
    setConnect,
    close,
    emit,
    on,
    removeListener,
  }
})