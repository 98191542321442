import {ref, computed} from 'vue'
import {defineStore} from 'pinia'

export const usePreloaderStore = defineStore('preloader', () => {
  const whoLoaded = ref([])
  const showLoader = computed(() => !!whoLoaded.value.length)
  
  function activate(whoLoadedName,...args) {
    whoLoaded.value.push(whoLoadedName)
    for (let item of args) {
      whoLoaded.value.push(whoLoadedName)
    }
  }
  
  function deactivate(firstWhoLoadedName,...args) {
    if (firstWhoLoadedName === 'all' || firstWhoLoadedName === 'All') {
      whoLoaded.value = []
      return
    } else {
      const index = whoLoaded.value.indexOf(firstWhoLoadedName)
      if (index !== -1) {
        whoLoaded.value.splice(index, 1)
      }
    }
    for (let whoLoadedName of args) {
      const index = whoLoaded.value.indexOf(whoLoadedName)
      if (index !== -1) {
        whoLoaded.value.splice(index, 1)
      }
    }
  }
  
  return {
    showLoader,
    whoLoaded,
    activate,
    deactivate
  }
})
