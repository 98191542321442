<script setup="">
import {computed, onBeforeMount, onMounted, ref} from "vue";
import AppBackground from "@/components/AppBackground.vue";
import AppButton from "@/components/AppButton.vue";
import axiosInstance from "@/api.js";
import {useMetaStore} from "@/stores/meta.js";
import {useI18n} from "vue-i18n";
import configI18n from "@/i18n/config.js";

const {t} = useI18n({useScope: 'global'})

const data = ref([])

const pageNum = ref(0)
const numTabsInPage = ref(5)

const getDataInPage = computed(() => {
  let result = []

  for (let i = 0; i < numTabsInPage.value; i++) {
    const index = i + numTabsInPage.value * pageNum.value
    if (data.value.length > index) {
      result.push(data.value[index])
    }
  }

  return result
})

const getNumAllPages = computed(() => {
  return Math.ceil(data.value.length / numTabsInPage.value)
})

onBeforeMount(async () => {
  try {
    let updateInfoData = await axiosInstance.get('/getUpdateInfo')
    if (updateInfoData.data) {
      data.value = updateInfoData.data.reverse()
    }
  } catch (e) {
    console.log(e)
  }
})

function changePage(index) {
  pageNum.value = index
  window.scrollTo(0, 0)
}

</script>

<template>
  <main class="updates paddingTop">
    <div class="updates__wrapper">
      <span>
        <AppBackground img-name="updates.jpg"/>
      </span>
    </div>

    <div class="updates__container">
      <h1 class="updates__title titleH1">{{ t('updates.body.title') }}</h1>
      <div class="pagination-updates" v-if="getNumAllPages>1">
        <AppButton
            color="gold"
            :border="index-1!==pageNum"
            v-for="index in getNumAllPages"
            :key="index"
            @click="changePage(index-1)"
        >
          {{ index }}
        </AppButton>
      </div>
      <div class="updates__body">
        <div class="tab-updates linear-border gold"
             v-for="item in getDataInPage"
             :key="item.id"
        >
          <h2 class="tab-updates__title" v-html="configI18n.tData(item.title)"></h2>
          <p class="tab-updates__text" v-html="configI18n.tData(item.text)"></p>
          <small class="tab-updates__date" v-html="item.dateText"></small>
        </div>
      </div>
      <div class="pagination-updates" v-if="getNumAllPages>1">
        <AppButton
            color="gold"
            :border="index-1!==pageNum"
            v-for="index in getNumAllPages"
            :key="index"
            @click="changePage(index-1)"
        >
          {{ index }}
        </AppButton>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
.updates {
  position: relative;

  &__container {
  }

  &__title {
  }

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    span {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    min-height: 100vh;
  }
}

.tab-updates {
  margin-bottom: 40px;
  padding: 35px 30px;

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &__text {
    font-size: 14px;
    line-height: 1.8;
    margin-bottom: 20px;
  }

  &__date {
    font-size: 11px;
    font-weight: 600;
  }
}

.pagination-updates {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;

  button {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    font-weight: 700;
  }
}

</style>