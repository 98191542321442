<script async setup>
import {useAuthStore} from "@/stores/auth.js";
import {usePreloaderStore} from "@/stores/preloader.js";
import {useMyProfileStore} from "@/stores/profile.js";
import {RouterView} from 'vue-router'
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import AppUpButton from "@/components/AppUpButton.vue";
import {onBeforeMount, onMounted} from "vue";
import AppPreloader from "@/components/AppPreloader.vue";
import {getLinkParams, getLocalData} from "@/plugins/functions.js";
import AppConfirm from "@/components/AppConfirm.vue";
import AppPopup from "@/components/AppPopup.vue";
import {useDBPopupStore, useGlobalPopupStore} from "@/stores/popup.js";
import {useConfirmBlockStore} from "@/stores/confirmBlock.js";
import {useAuthSocketStore} from "@/stores/socket/authSocket.js";
import router from "@/router/index.js";
import configI18n from "@/i18n/config.js";

const authStore = useAuthStore()
const myProfile = useMyProfileStore()
const globalPopup = useGlobalPopupStore()
const globalPreloader = usePreloaderStore()
const confirmStore = useConfirmBlockStore()
const authSocket = useAuthSocketStore()
const DBPopup = useDBPopupStore()

const checkUser = () => {
  const tokens = authStore.getLocalData('userTokens')
  if (tokens) {
    myProfile.token = tokens.token
  }

  const userId = authStore.getLocalData('userId')
  if (userId) {
    myProfile.id = userId
  }
}
// const showBetaPopup = ref(false)

checkUser()

onBeforeMount(async () => {
  let params = getLinkParams()
  setTimeout(async () => {
    if (!(params['account'] && params['account'] === "connected")
        && !(router.currentRoute.value.name === 'profile' && +myProfile.id === +router.currentRoute.value.params.id)) {
      await myProfile.setMyProfileInfo(false)
      authSocket.setConnect()
    }
  }, 0)

  ///
  // Функции перед загрузкой основной страницы приложения
  ///
  await DBPopup.setPopup()


  /**
   * @description Автоматическая отчистка LocalStorage на старые записи в заметках, которые были сделаны 3 дня назад
   */
  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    if (key.includes('note:game=')) {
      let data = getLocalData(key)
      if (data && data.date && ((new Date()) - new Date(data.date)) > 2.592e+8) {
        localStorage.removeItem(key)
      }
    }
    if (key.includes('game:')) {
      let data = getLocalData(key)
      if (data && data.date && ((new Date()) - new Date(data.date)) > 2.592e+8) {
        localStorage.removeItem(key)
      }
    }
    // if(key.includes('betaTestPopup')) {
    //   let data = getLocalData(key)
    //   if(data && data.date) {
    //     if(((new Date()) - new Date(data.date))>1000*60*60*24) {
    //       localStorage.removeItem(key)
    //     }
    //   } else {
    //     localStorage.removeItem(key)
    //   }
    // }
  }
})

onMounted(() => {
  // let betaPopupLocal = getLocalData('betaTestPopup')
  // if (!betaPopupLocal) {
  //   setLocalData('betaTestPopup', {read:true,date: +(new Date())})
  //   showBetaPopup.value = true
  // }

  authSocket.setConnect()
})

</script>

<template>
  <TheHeader tabindex="0"
             @keyup.esc.exact="confirmStore.deactivate"
             @keyup.enter.exact="confirmStore._enterHandler"></TheHeader>
  <AppPreloader :class="globalPreloader.showLoader?'':'_deactivate'"/>
  <RouterView tabindex="0"
              @keyup.esc.exact="confirmStore.deactivate"
              @keyup.enter.exact="confirmStore._enterHandler"/>
  <TheFooter></TheFooter>
  <AppUpButton/>
  <AppConfirm/>
  <AppPopup v-model="globalPopup.show" :color="globalPopup.color" :timer="globalPopup.isTimer">
    <template v-slot:title>
      {{ globalPopup.title }}
    </template>
    <div v-html="globalPopup.text" style="text-align: center;"></div>
  </AppPopup>

  <AppPopup v-model="DBPopup.isShow" :color="DBPopup.color" :bold-border="DBPopup.isBoldBorder">
    <template v-slot:title>
      <p style="text-align: center" v-html="configI18n.tData(DBPopup.title)"></p>
    </template>
    <p style="text-align: center" v-html="configI18n.tData(DBPopup.text)"></p>
  </AppPopup>

  <!--  <AppPopup v-model="showBetaPopup" color="gold">-->
  <!--    <template v-slot:title>-->
  <!--      <p class="redTextColor" style="font-size: 28px;">Внимание!</p>-->
  <!--    </template>-->
  <!--    <p style="text-align: center">-->
  <!--      На сайте проводятся технические работы, могут наблюдаться перебои в работе.<br>-->
  <!--      Приносим извинения за неудобства.-->
  <!--    </p>-->
  <!--  </AppPopup>-->
</template>

<style lang="scss">
@import "@/assets/scss/style.scss";
@import "@/assets/scss/scrollbar";
</style>
