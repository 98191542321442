import {defineStore} from "pinia";
import {ref} from "vue";

export const useStaticPagesStore = defineStore('staticPagesStpre', () => {
  const wikiListLinks = ref([])
  const rulesPagesHTML = ref('')
  const imageName = ref('')
  
  return {
    wikiListLinks,
    rulesPagesHTML,
    imageName
  }
})
