import '@/assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './api.js'
import i18n from "@/i18n/index.js";

import App from './App.vue'
import router from './router'
import dynamicAdaptiveDirective from "@/directives/dynamicAdaptiveDirective.js";
import spoilerSlide from "@/directives/spoilerSlide.js";

const pinia = createPinia()
const app = createApp(App)

app.use(i18n)
app.use(pinia)
app.use(router)
app.directive('adaptive', dynamicAdaptiveDirective)
app.directive('slide', spoilerSlide)

app.mount('#app')
