import {defineStore} from "pinia";
import {computed, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";

export const useStaticData = defineStore('staticData', () => {
  const {t} = useI18n({useScope: 'global'})
  const numName = [computed(() => t('playerCharacters.numName')), 'num']
  const defaultItems = [
    [computed(() => t('playerCharacters.sex')), 'sex'],
    [computed(() => t('playerCharacters.body')), 'body'],
    [computed(() => t('playerCharacters.trait')), 'trait'],
    [computed(() => t('playerCharacters.profession.title')), 'profession', computed(() => t('playerCharacters.profession.addInfo'))],
    [computed(() => t('playerCharacters.health')), 'health'],
    [computed(() => t('playerCharacters.hobbies.title')), 'hobbies', computed(() => t('playerCharacters.hobbies.addInfo'))],
    [computed(() => t('playerCharacters.phobia')), 'phobia'],
    [computed(() => t('playerCharacters.inventory')), 'inventory'],
    [computed(() => t('playerCharacters.backpack')), 'backpack'],
    [computed(() => t('playerCharacters.addInfo')), 'addInfo'],
  ]
  const specItems = [
    [computed(() => t('playerCharacters.spec1')), 'spec1'],
    [computed(() => t('playerCharacters.spec2')), 'spec2'],
  ]
  const subWikiTitles = reactive({
    ru: '',
    en: ''
  })
  const allGamesLoadedBefore = ref(false)
  
  function getAccessStr(access) {
    return t('accessLevelNames.' + access.toLowerCase())
  }
  
  
  return {
    numName,
    defaultItems,
    specItems,
    subWikiTitles,
    allGamesLoadedBefore,
    getAccessStr
  }
})
