import router from "@/router/index.js";
import {usePreloaderStore} from "@/stores/preloader.js";
import {useGlobalPopupStore} from "@/stores/popup.js";
import {useAuthStore} from "@/stores/auth.js";
import {useMyProfileStore} from "@/stores/profile.js";
import {useSelectedGame, useSelectedGameData} from "@/stores/game.js";
import configI18n from "@/i18n/config.js";
import i18n from "@/i18n/index.js";
import {computed} from "vue";

const t = i18n.global.t

export async function switchError(data, socket) {
  const message = configI18n.tData(data.message)
  const status = data.status
  const functionName = data.functionName
  const vars = data.vars
  const color = data.color
  const wrongData = data.wrongData


  const globalPopup = useGlobalPopupStore()
  const globalPreloader = usePreloaderStore()
  const authStore = useAuthStore()
  const myProfile = useMyProfileStore()
  const selectedGame = useSelectedGame()
  const selectedGameData = useSelectedGameData()

  let tryToReconnect = false
  let tryToReconnectCount = 0

  switch (status) {
    case 403: {
      if (!tryToReconnect) {
        tryToReconnect = true
        socket.close()
        let tryToReconnectInterval = setInterval(async () => {
          if (tryToReconnectCount <= 3) {
            tryToReconnectCount++
            await authStore.refreshToken()
            socket.auth.token = myProfile.token
            socket.connect()
            if (socket.connected) {
              if (vars) {
                socket.emit(functionName, ...vars)
              } else {
                socket.emit(functionName)
              }

              clearInterval(tryToReconnectInterval)
              globalPreloader.deactivate('refreshToken:socket')
            } else {
              if (tryToReconnectCount === 2) {
                globalPreloader.activate('refreshToken:socket')
              }
            }
          } else {
            clearInterval(tryToReconnectInterval)
            await router.push({name: 'home'})
            tryToReconnectCount = 0
            globalPopup.activate(t('globalPopup.connectError.title'), message, t('globalPopup.connectError.color'))
            globalPreloader.deactivate('refreshToken:socket')
          }
        }, 1000)
      } else {
        console.error('Повторная ошибка 403 при уже посланном запросе')
      }

      break;
    }
    case 404: {
      selectedGame.clearData()
      selectedGame.isNotFoundGame = true
      break;
    }
    case 469: {
      myProfile.clearUserInfo()
      await router.push({name: 'home'})
      globalPopup.activate(
        t('globalPopup.bannedAccount.title'),
        t('globalPopup.bannedAccount.text'),
        t('globalPopup.bannedAccount.color')
      )
      break;
    }
    case 512: {
      selectedGame.isStarted = false
      globalPopup.activate(t('globalPopup.createDataError.title'), message, t('globalPopup.createDataError.color'))
      break;
    }
    case 601: {
      globalPopup.activate(t('globalPopup.openChartError.title'), message, t('globalPopup.openChartError.color'))
      selectedGameData.getMyPlayerData[wrongData.chartName].isLoading = false;
      break;
    }
    case 701: {
      globalPopup.activate(t('globalPopup.defaultError.title'),
        message,
        // + "<br>" + computed(() => {
        //   let resultString = ''
        //   wrongData.forEach(nick => {
        //     resultString +=configI18n.tData(nick) + "<br>"
        //   })
        //   return resultString
        // }).value,
        color || t('globalPopup.defaultError.color'))
      break;
    }

    default: {
      globalPopup.activate(t('globalPopup.defaultError.title'), message, color || t('globalPopup.defaultError.color'))
    }
  }
}