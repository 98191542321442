<script setup="">
import {useI18n} from "vue-i18n";

const {t} = useI18n({useScope: 'global'})
</script>

<template>
  <div class="cookiePage paddingTop">
    <div class="cookiePage__container">
      <h1 class="cookiePage__title titleH1" v-html="t('cookie.body.title')"></h1>
      <div class="cookiePage__body">
        <p class="cookiePage__text" v-html="t('cookie.body.text')"></p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.cookiePage {
  &__container {
  }

  &__title {
  }

  &__body {
  }

  &__text {
    font-size: 14px;
    line-height: 1.2;

    a {
      text-decoration: underline;

      @media (any-hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>