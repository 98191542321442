import i18n from "@/i18n"
import {nextTick} from "vue"
import axiosInstance from "@/api.js";
import {usePreloaderStore} from "@/stores/preloader.js";
import {objIsEmpty} from "@/plugins/functions.js";

const configI18n = {
  get defaultLocale() {
    return import.meta.env.VITE_DEFAULT_LOCALE
  },

  get supportedLocales() {
    return import.meta.env.VITE_SUPPORTED_LOCALES.split(",")
  },

  get currentLocale() {
    return i18n.global.locale.value
  },

  set currentLocale(newLocale) {
    i18n.global.locale.value = newLocale
  },

  async switchLanguage(newLocale) {
    const globalPreloader = usePreloaderStore()
    try {
      globalPreloader.activate('i18n:switchLanguage')
      await configI18n.loadLocaleMessages(newLocale)
      configI18n.currentLocale = newLocale
      document.querySelector("html").setAttribute("lang", newLocale)
      localStorage.setItem("user-locale", newLocale)
    } catch (e) {
      console.error(e)
    } finally {
      globalPreloader.deactivate('i18n:switchLanguage')
    }
  },

  async loadLocaleMessages(locale) {
    if (!i18n.global.availableLocales.includes(locale)) {
      try {
        const messages = await axiosInstance.get(import.meta.env.VITE_LOCALE_PATH + `${locale}.json`)
        if (messages && messages.data && !objIsEmpty(messages.data)) {
          i18n.global.setLocaleMessage(locale, messages.data)
        } else {
          console.error('Ошибка сбора локализации из сети, пробуем из локали')
          const messages = await import('./locales/' + locale + '.json')
          i18n.global.setLocaleMessage(locale, messages.default)
        }
      } catch (e) {
        console.error('Ошибка сбора локализации из сети, пробуем из локали')
        const messages = await import((`./locales/${locale}.json`))
        i18n.global.setLocaleMessage(locale, messages.default)
      }
    }

    return nextTick()
  },

  isLocaleSupported(locale) {
    return configI18n.supportedLocales.includes(locale)
  },

  getUserLocale() {
    const locale = window.navigator.language ||
      window.navigator.userLanguage ||
      configI18n.defaultLocale

    return {
      locale: locale,
      localeNoRegion: locale.split('-')[0]
    }
  },

  getPersistedLocale() {
    const persistedLocale = localStorage.getItem("user-locale")

    if (configI18n.isLocaleSupported(persistedLocale)) {
      return persistedLocale
    } else {
      return null
    }
  },

  guessDefaultLocale() {
    const userPersistedLocale = configI18n.getPersistedLocale()
    if (userPersistedLocale) {
      return userPersistedLocale
    }

    const userPreferredLocale = configI18n.getUserLocale()

    if (configI18n.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale
    }

    if (configI18n.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
      return userPreferredLocale.localeNoRegion
    }

    return configI18n.defaultLocale
  },

  i18nRoute(to) {
    return {
      ...to,
      params: {
        locale: configI18n.currentLocale,
        ...to.params
      }
    }
  },

  tData(data) {
    if (!data) {
      return ""
    }
    if (!(data.hasOwnProperty("ru") || data.hasOwnProperty("en"))) {
      return data
    }
    return data[configI18n.currentLocale]
  },

  async routerMiddleware(to, from, next) {
    const paramLocale = to.params.locale
    const localLanguage = localStorage.getItem("user-locale")

    if (localLanguage) {
      if (!configI18n.isLocaleSupported(localLanguage)) {
        to.params.locale = configI18n.guessDefaultLocale()
        await configI18n.switchLanguage(to.params.locale)
        return next(to)
      }
      if (!i18n.global.availableLocales.includes(localLanguage)) {
        await configI18n.switchLanguage(localLanguage)
        to.params.locale = localLanguage
        return next(to)
      }
      if (!to.params.locale) {
        await configI18n.switchLanguage(localLanguage)
        to.params.locale = localLanguage
        return next(to)
      }

    } else if (paramLocale) {
      if (!configI18n.isLocaleSupported(paramLocale)) {
        to.params.locale = configI18n.guessDefaultLocale()
        await configI18n.switchLanguage(to.params.locale)
        return next(to)
      } else if (paramLocale !== configI18n.currentLocale) {
        await configI18n.switchLanguage(paramLocale)
      } else if (!i18n.global.availableLocales.length) {
        await configI18n.switchLanguage(configI18n.currentLocale)
      }
    } else {
      if (configI18n.currentLocale) {
        to.params.locale = configI18n.currentLocale
        if (!i18n.global.availableLocales.length) {
          await configI18n.switchLanguage(configI18n.currentLocale)
        }
        return next(to)
      } else {
        to.params.locale = configI18n.guessDefaultLocale()
        await configI18n.switchLanguage(to.params.locale)
        return next(to)
      }
    }
    if (to.name !== "subWiki" && to.meta.title) {
      document.title = i18n.global.t(`${to.meta.title}.title`);
    }
    console.log(i18n.global.locale.value)
    if(i18n.global.locale.value==='en') {
      let metaDescription = document.querySelector('meta[name="description"]');

      if (metaDescription) {
        metaDescription.setAttribute('content', '«Bunker Online!» - An incredibly atmospheric game with a great narrative part! This is a unique opportunity to experience the coming of the apocalypse, and prove that it is you who should be in the bunker, and not someone else!');
      }
    } else {
      let metaDescription = document.querySelector('meta[name="description"]');

      if (metaDescription) {
        metaDescription.setAttribute('content', '«Бункер Онлайн!» - невероятно атмосферная игра с великолепной нарративной частью! Это уникальная возможность испытать на себе наступление апокалипсиса,  и доказать что именно ты должен быть в бункере, а не кто-то другой!');
      }
    }

    return next()
  },

}

export default configI18n