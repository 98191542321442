import {ref, computed} from 'vue'
import {defineStore} from 'pinia'
import router from "@/router/index.js";
import {useAuthStore} from "@/stores/auth.js";
import {useMyProfileStore} from "@/stores/profile.js";
import {usePreloaderStore} from "@/stores/preloader.js";
import {useGlobalPopupStore} from "@/stores/popup.js";
import {useSelectedGame, useSelectedGameData, useSelectedGameGameplay} from "@/stores/game.js";
import { userSocket} from "@/socket/sockets.js";
import {useHostSocketStore} from "@/stores/socket/hostSocket.js";
import {switchError} from "@/logics/socketLogic.js";
import {useMetaStore} from "@/stores/meta.js";
import configI18n from "@/i18n/config.js";
import {useI18n} from "vue-i18n";

export const useUserSocketStore = defineStore('userSocket', () => {
  const {t} = useI18n({useScope: 'global'})
  
  const connected = ref(false)
  
  const authStore = useAuthStore()
  const myProfile = useMyProfileStore()
  const globalPreloader = usePreloaderStore()
  const globalPopup = useGlobalPopupStore()
  const selectedGame = useSelectedGame()
  const hostSocket = useHostSocketStore()
  const selectedGameData = useSelectedGameData()
  const selectedGameGameplay = useSelectedGameGameplay()
  const metaStore = useMetaStore()
  
  
  function bindEvents() {
    userSocket.on('setError', async data => {
      await switchError(data, userSocket)
      
      globalPreloader.deactivate('all')
    })
    userSocket.on("connect_error", (err) => {
      globalPreloader.deactivate('all')
      // globalPopup.activate('Ошибка','Сервер перестал отвечать на запросы. Пожалуйста, обновите страницу.')
    });
    userSocket.on('updateInitialInfo', () => {
      globalPreloader.activate('game:updateInitialInfo')
      userSocket.emit('getAwaitRoomData')
    })
    userSocket.on('kickOut', async data => {
      globalPopup.activate(
        t('globalPopup.kickOutForGame.title'),
        t('globalPopup.kickOutForGame.text'),
        t('globalPopup.kickOutForGame.color'))
      await router.push({name: 'home'})
    })
    userSocket.on('setNoregToken', noRegToken => {
      console.log('setNoregToken', noRegToken)
      myProfile.setNoregToken(noRegToken)
    })
    userSocket.on('joinedRoom', data => {
      globalPreloader.activate('game:joinedRoom')
      userSocket.emit('getAwaitRoomData')
    })
    userSocket.on('setAwaitRoomData', data => {
      if (!data) {
        selectedGame.isNotFoundGame = true
      } else {
        if (data.hostId && data.userId) {
          globalPreloader.activate('hostIdUserId')
        }
        selectedGame.setInitialData(data)
        console.log('setAwaitRoomData', data)
        
        hostSocket.setConnect()
      }
      
      
      globalPreloader.deactivate(
        'game:joinedRoom',
        'hostIdUserId',
        'game:updateInitialInfo',
        'game:awaitData',
        'game:connection'
      )
    })
    userSocket.on('roomClosed', async (data) => {
      if (selectedGame.isStarted) {
        globalPopup.activate(
          t('globalPopup.roomClosed.title'),
          t('globalPopup.roomClosed.text'),
          t('globalPopup.roomClosed.color'))
      } else {
        globalPopup.activate(
          t('globalPopup.roomClosed.title'),
          t('globalPopup.roomClosed.text'),
          t('globalPopup.roomClosed.color'))
      }
      await router.push({name: 'home'})
    })
    userSocket.on('sendMessage', data => {
      const title = configI18n.tData(data.title)
      const message = configI18n.tData(data.message)
      const color = data.color
      globalPopup.activate(title || t('globalPopup.serverMessageDefaultTitle'), message || '', color)
    })
    userSocket.on('sendMessage:timer', data => {
      const title = configI18n.tData(data.title)
      const message = configI18n.tData(data.message)
      const color = data.color
      globalPopup.activate(title || t('globalPopup.serverMessageDefaultTitle'), message || '', color, true)
    })
    userSocket.on('startedGame', async data => {
      globalPreloader.activate('game:awaitData')
      globalPreloader.deactivate('game:connection', 'startGame')
      metaStore.setTitle(t('game.startGameTitle'))
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0)
    })
    userSocket.on('setAllGameData', async data => {
      console.log('Приняли все данные по игре', data)
      if (!selectedGameData.getAlivePlayers) {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0)
      }
      selectedGameData.setData(data)
      selectedGame.isStarted = true
      globalPreloader.deactivate('game:updateInitialInfo', 'game:awaitData')
    })
    userSocket.on("connection:good", () => {
      console.log('Подключились по Socket.io USERSOCKET')
      globalPreloader.activate('game:connection')
      globalPreloader.deactivate('loadGame')
      
      if (!userSocket.auth._retry) {
        if (selectedGame.isNewGame) {
          userSocket.emit('createRoom')
          selectedGame.isNewGame = false
        } else {
          userSocket.emit('joinRoom')
        }
      } else {
        userSocket.emit('joinRoom')
      }
    });
    
    
    userSocket.on('timer:start', ({seconds, nowDate}) => {
      selectedGameGameplay.startTimer(seconds)
    })
    userSocket.on('timer:pause', () => {
      selectedGameGameplay.pauseTimer()
    })
    userSocket.on('timer:resume', ({seconds, nowDate}) => {
      selectedGameGameplay.resumeTimer(seconds)
    })
    userSocket.on('timer:stop', () => {
      selectedGameGameplay.stopTimer()
    })
    userSocket.on('rollTheDice:6', (num) => {
      selectedGameGameplay.rollDice(6, num)
    })
    userSocket.on('rollTheDice:20', (num) => {
      selectedGameGameplay.rollDice(20, num)
    })
    
    userSocket.on('refresh:professionByHour:good', () => {
      globalPopup.activate('Сообщение от ведущего', 'Смена специальности по часовой стрелке', 'green', true)
    })
    userSocket.on('voiting:start', async () => {
      globalPopup.activate(
        t('game.popups.voteStart.title'),
        t('game.popups.voteStart.text'),
        t('game.popups.voteStart.color'),
        true
      )
      await router.push('#voting')
    })
    
    
    userSocket.on('restartGame', () => {
      globalPreloader.activate('game:restartGame')
      selectedGameData.logs = []
      globalPopup.activate(
        t('game.popups.restartGame.title'),
        t('game.popups.restartGame.text'),
        t('game.popups.restartGame.color'),
        true
      )
      globalPreloader.deactivate('game:restartGame')
    })
  }
  
  function connect() {
    userSocket.auth = {
      noregToken: authStore.getLocalData('noregToken'),
      token: myProfile.token,
      idRoom: router.currentRoute.value.path.split('=')[1],
      _retry: false,
    }
    userSocket.connect()
    connected.value = userSocket.connected
  }
  
  function close() {
    userSocket.close()
    userSocket.off()
    userSocket.removeAllListeners();
    connected.value = userSocket.connected
  }
  
  /**
   * Обычный Emit через socket.io
   * @param {string} funcName
   * @param [args]
   */
  function emit(funcName, ...args) {
    userSocket.emit(funcName, ...args)
  }
  
  function on(funcName, functionStr) {
    userSocket.on(funcName, functionStr)
  }
  
  function removeListener(funcName) {
    userSocket.removeListener(funcName)
  }
  
  return {
    connected,
    bindEvents,
    connect,
    close,
    emit,
    on,
    removeListener,
  }
})