<script setup="">
import AppBackground from "@/components/AppBackground.vue";
import {openNavigation} from "@/plugins/navigationPlugin.js";
import {computed, nextTick, onBeforeMount, ref, watch} from "vue";
import {usePreloaderStore} from "@/stores/preloader.js";
import axiosInstance from "@/api.js";
import configI18n from "@/i18n/config.js";
import router from "@/router/index.js";


const globalPreloader = usePreloaderStore()

const navBlock = ref()
const pageHTML = ref('')

const navigationData = ref([])

watch(computed(() => configI18n.tData(pageHTML.value)), (a, b) => {
  nextTick(() => {
    const results = [];

    let body = document.querySelector('#rulesBody')

    const h2Elements = body.querySelectorAll('h2');
    if (h2Elements) {
      h2Elements.forEach(item => {
        if (item.id && item.textContent) {
          results.push({link: item.id, name: item.textContent});
        }
      })
    }

    navigationData.value = results
  })
})


onBeforeMount(async () => {
  globalPreloader.activate('rulesMount')

  try {
    let pageHTMLData = await axiosInstance.get('/staticPage/rules')
    if (pageHTMLData.data.html) {
      pageHTML.value = pageHTMLData.data.html

      if (router.currentRoute.value.hash) {
        const hash = router.currentRoute.value.hash.toString()
        await router.push({name: "rules", hash: hash})
      }
    }
  } catch (e) {
    console.log(e)
  }

  globalPreloader.deactivate('rulesMount')
})

</script>

<template>
  <main class="rules-p">
    <div class="rules-p__wrapper">
      <span>
        <AppBackground img-name="await.jpg"/>
      </span>
    </div>
    <div class="rules-p__container">
      <div class="rules-p__body">
        <div @click="openNavigation(navBlock)" class="pagination-rules-p">
          <div ref="navBlock" class="pagination-rules-p__block linear-border white">
            <ul class="pagination-rules-p__list">
              <li class="pagination-rules-p__item"
                  v-for="(item) in navigationData"
                  :key="item.link+item.name"
              >
                <router-link class="pagination-rules-p__link" :to="`#${item.link}`">{{ item.name }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div id="rulesBody" class="main-rules-p" v-html="configI18n.tData(pageHTML)">
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
@import '@/assets/scss/style';

.rules-p {
  padding-top: 130px;
  position: relative;
  z-index: 5 !important;
  line-height: 1.5;

  &__container {
  }

  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    span {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 25fr 75fr;
    gap: 30px;

    @media (max-width: $mobile) {
      display: flex;
    }
  }
}

.pagination-rules-p {
  position: relative;

  @media (max-width: $mobile) {
    position: fixed;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    font-size: 11px;
    font-weight: 600;
    top: 50%;
    transform: translate(0, -50%);
    padding: 20px 0;
    width: 30px;
    height: 405px;
  }

  &__block {
    padding: 30px 23px !important;
    position: fixed !important;
    max-width: 23% !important;

    @media (max-width: $mobile) {
      position: absolute !important;
      display: flex !important;;
      max-width: 100% !important;;
      transform: translateX(-95%) !important;;
      transition: transform 0.2s ease !important;;
      width: 216px !important;;
      min-width: 216px !important;;
      left: 0 !important;;
      top: 0 !important;;

      &._active {
        transform: translateX(15px) !important;;
      }
    }
  }

  &__list {

    @media (max-width: $mobile) {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {


    &._active, &:hover {
      background: linear-gradient(180deg, #F9D35B, #D96613);
      background-clip: border-box;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.main-rules-p {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 5px;

  @media (max-width: $mobile) {
    flex: 1 1 auto;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.4;
    border-radius: 6px;
    filter: blur(110.5px);
    z-index: -1;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
    background: linear-gradient(90deg, rgb(249, 211, 91), rgb(217, 102, 19) 25%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__body {
  }

  h2, h3 {
    font-weight: 700;
  }


  h2 {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 14px;
    line-height: 1.8;
  }

  p {
    line-height: 1.8;
    color: #e5e5e5;
  }

  ul {
    & > li {
      margin-bottom: 50px;
      overflow-x: auto;

      &:last-child {
        margin-bottom: 0;
      }

      & > ul {
        li {
          margin-bottom: 6px;
          overflow-x: inherit;

          &:last-child {
            margin-bottom: 6px;
          }

          &::before {
            content: '- ';
          }
        }
      }
    }
  }

  &__mainList {


  }

  a {
    text-decoration: underline !important;
    color: #d7b14b !important;

    @media (any-hover: hover) {
      &:hover {
        text-decoration: none !important;
      }
    }
  }

  table {
    width: 100%;
    background-color: #00000080;
    padding: 15px;
    border-collapse: collapse;
    border: 1px solid #181818;
    border-radius: 8px;

    caption {
      font-size: 20px;
      margin-bottom: 15px;
      font-weight: 700;
    }

    tbody {
      tr {
        border-bottom: 1px solid #181818;
      }

      td {
        text-align: center;
        padding: 12px;
        border-right: 1px solid #181818;
      }
    }

    thead {
      th {
        border-bottom: 3px solid #181818;
        border-right: 1px solid #181818;
        padding: 13px;
        font-weight: 700;
        font-size: 13px;
        text-align: center;
      }
    }
  }
}

//.table {
//  display: flex;
//  flex-direction: column;
//  overflow-x: auto;
//  min-width: 530px;
//
//  &__body {
//  }
//
//  &__row {
//    display: grid;
//    grid-template-columns: 25fr 25fr 50fr;
//    border-bottom: 1px solid #181818;
//
//    &:first-child {
//      border-top: 1px solid #181818;
//    }
//  }
//
//  &__column {
//    padding: 10px;
//    border-right: 1px solid #181818;
//
//    &:first-child {
//      border-left: 1px solid #181818;
//    }
//  }
//}

</style>