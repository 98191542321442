import {emailTest, slideUp} from "@/plugins/functions.js";

// const forbiddenCharacters = [
//   '&', '=', '+', '<', '>', ',', "'", '"', '`', '..', '--', ':',
//   '{', '}', '$', '#', '@', '!', '%', '^', '*', '(', ')', '?', '/',
//   '\\', '[', ']', ';', '~', '  '
// ]

const forbiddenCharacters = [
  '#', '@', '=', '+', '<', '>', ',', "'", '"', '`', '..', '--', ';', ',', '  '
]

export function validationRegistration({email, nickname, password, passwordRepeat}, t) {
  let errors = {}
  
  if (!email || !emailTest(email)) {
    errors['email'] = t('inputValidationErrors.email')
  }
  
  //Проверка на никнейм
  if (!nickname) {
    errors['nickname'] = t('inputValidationErrors.nickname.mustBe')
  } else {
    if (nickname.length < 3) {
      errors['nickname'] = t('inputValidationErrors.nickname.minLength')
    }
    if (nickname.length > 15) {
      errors['nickname'] = t('inputValidationErrors.nickname.maxLength')
    }
    let errorsInput = forbiddenInputNickname(nickname)
    if (errorsInput.forbiddenCharArray.length) {
      errors['nickname'] = t('inputValidationErrors.nickname.invalidChars.title') + `\r\n`
      errors['nickname'] += t('inputValidationErrors.nickname.invalidChars.firstText') + ' '
        + forbiddenCharacters
          .join(' ')
          .replace('   ', ' ' + t('inputValidationErrors.nickname.invalidChars.doubleSpace')) + ''
      // errors['nickname'] += ' ( ' + errorsInput.forbiddenCharArray.join(' ') + ' )'
    }
    if (errorsInput.lettersLength) {
      if (!(errors['nickname'] && errors['nickname'].length)) {
        errors['nickname'] = ''
      }
      errors['nickname'] += `\r\n` + t('inputValidationErrors.nickname.minLetters')
    }
    if (errorsInput.specCharsLength) {
      if (!(errors['nickname'] && errors['nickname'].length)) {
        errors['nickname'] = ''
      }
      errors['nickname'] += `\r\n` + t('inputValidationErrors.nickname.maxSpecialChars')
    }
  }
  
  //Проверка на пароль
  if (!password) {
    errors['password'] = t('inputValidationErrors.password.mustBe')
  } else {
    if (passwordRepeat !== password) {
      errors['password'] = t('inputValidationErrors.password.mustRepeat')
      errors['passwordRepeat'] = t('inputValidationErrors.password.mustRepeat')
    }
    
    if (!passwordRepeat) {
      errors['passwordRepeat'] = t('inputValidationErrors.password.repeatPassword')
    }
    
    if (password.length < 5) {
      errors['password'] = t('inputValidationErrors.password.minLength')
    }
  }
  
  return errors
}

export function testNicknameKey(key) {
  // let testResult = /[a-zA-Z]/.test(key) ||
  //   /[а-яА-Я]/.test(key) ||
  //   /[0-9]/.test(key) ||
  //   key==="Backspace" ||
  //   key==="_" ||
  //   key==="." ||
  //   key===" "
  let testResult = forbiddenCharacters.find(char => char === key)
  if (testResult === '  ') {
    testResult = 'Двойной пробел'
  }
  return testResult
}

export function forbiddenInputNickname(value) {
  let specCharsLength = false
  let lettersLength = false
  
  let forbiddenCharArray = forbiddenCharacters.reduce((total, char) => {
    if (value.includes(char)) {
      total.push(char)
    }
    
    return total
  }, [])
  
  let letters = value.match(/[a-zA-Z]|[а-яА-Я]/g)
  if (letters) {
    letters = letters.join('')
  } else {
    letters = []
  }
  let nums = value.match(/\d+/g)
  if (nums) {
    nums = nums.join('')
  } else {
    nums = []
  }
  
  if (!letters || letters.length < 3) {
    lettersLength = true
  } else if ((value.length - letters.length - nums.length) > 3) {
    specCharsLength = true
  }
  return {forbiddenCharArray, specCharsLength, lettersLength}
}

export function clearError(el) {
  let small = el.parentNode.querySelector('small')
  el.classList.remove('_error')
  
  small.style.opacity = "0"
}

export function clearFormElementsError(form) {
  let inputs = form.querySelectorAll('input')
  inputs.forEach(input => {
    clearError(input)
  })
}