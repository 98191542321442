import {createRouter, createWebHistory, RouterView} from 'vue-router'
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Profile from "@/views/Profile.vue";
import Rules from "@/views/Rules.vue";
import Updates from "@/views/Updates.vue";
import Contacts from "@/views/Contacts.vue";
import {useMyProfileStore} from "@/stores/profile.js";
import Cookie from "@/views/Cookie.vue";
import configI18n from "@/i18n/config.js";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/:locale?",
      component: RouterView,
      // beforeEnter: await configI18n.firstRouterMiddleware,
      children: [
        {
          path: '',
          name: 'home',
          component: Home,
          meta: {title: 'home'}
        },
        {
          path: 'game=:id',
          name: 'game',
          component: () => import('../views/Game.vue')
        },
        {
          path: 'login',
          name: 'login',
          component: Login,
          meta: {title: 'login'}
        },
        {
          path: 'profile=:id',
          name: 'profile',
          component: Profile,
          meta: {title: 'profile'}
        },
        {
          path: 'rules',
          name: 'rules',
          component: Rules,
          meta: {title: 'rules'}
        },
        {
          path: 'cookie',
          name: 'cookie',
          component: Cookie,
          meta: {title: 'cookie'}
        },
        {
          path: 'wiki',
          name: 'wiki',
          component: () => import('../views/Wiki.vue'),
          meta: {title: 'wiki'}
        },
        {
          path: 'wiki/professional-abilities',
          name: 'profAbilities',
          component: () => import('../views/ProfAbilities.vue'),
          meta: {title: 'profAbilities'}
        },
        {
          path: 'wiki/:page',
          name: 'subWiki',
          component: () => import('../views/SubWiki.vue'),
          meta: {title: 'subWiki'}
        },
        {
          path: 'updates',
          name: 'updates',
          component: Updates,
          meta: {title: 'updates'}
        },
        {
          path: 'contacts',
          name: 'contacts',
          component: Contacts,
          meta: {title: 'contacts'}
        },
        {
          path: 'test',
          name: 'test',
          component: () => import('../views/TestAfterGame.vue'),
          meta: {title: 'testAfterGame'}
        },
        {
          path: 'not-found',
          name: 'notFoundPage',
          component: () => import('../views/NotFound.vue'),
          meta: {title: 'notFound'}
        },
        {
          path: ':pathMatch(.*)*',
          name: 'NotFound',
          redirect: '/not-found',
          meta: {title: 'notFound'}
        },
      ]
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition
    // } else
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        left: 0,
        top: document.querySelector('header').offsetHeight,
      }
    }
    if (to.params.locale !== from.params.locale || to.fullPath === from.fullPath) {
      return
    }
    return {left: 0, top: 0}
  }
})

router.beforeEach((to, from) => {
  const myProfile = useMyProfileStore()

  if (to.name === "login" && !myProfile.isNoReg) {
    return 'profile=' + myProfile.id
  }
})
router.beforeEach(configI18n.routerMiddleware)

export default router
