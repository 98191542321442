<script setup="">
import {useI18n} from "vue-i18n";
import configI18n from "@/i18n/config.js";

const {t} = useI18n({useScope: 'global'})
</script>

<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <img src="/img/backgrounds/footer.jpg" alt="">
      <img src="/img/backgrounds/up-shadow-footer.svg" alt="">
    </div>
    <div class="footer__upShadow">
    </div>
    <div class="footer__container">
      <h2 class="footer__title titleH2" v-html="t('footer.title')"></h2>
      <div class="footer__body">
        <p class="footer__text" v-html="t('footer.text')"></p>
        <div class="footer__images">
          <img src="/img/footer/12.png" alt="">
          <img src="/img/footer/online.png" alt="">
        </div>
      </div>
      <div class="footer__down down-footer ">
        <p class="down-footer__copy" v-html="t('footer.copyright')"></p>
        <router-link class="down-footer__cookie" :to="configI18n.i18nRoute({name:'cookie'})">{{t('footer.cookie')}}</router-link>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
@import "@/assets/scss/style";

.footer {
  position: relative;
  padding-bottom: 50px;
  padding-top: 150px;

  @media (max-width: $pc) {
    padding-top: 135px;
  }
  @media (max-width: $tablet) {
    padding-top: 115px;
  }
  @media (max-width: $mobile) {
    padding-top: 95px;
  }
  @media (max-width: $mobileSmall) {
    padding-top: 75px;
  }

  &__wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1024px;
      object-fit: cover;

      @media (max-width: $mobile) {
        height: 1075px;
      }

      &:nth-child(2) {
        //height: 70% !important;
      }
    }
  }

  &__container {
    position: relative;
    z-index: 3;
  }

  &__title {
  }

  &__body {
  }

  &__text {
    font-size: 14px;
    line-height: 1.8;
    text-align: center;
    max-width: 1050px;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  &__images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 100px;

    @media (max-width: $pc) {
      margin-bottom: 90px;
    }
    @media (max-width: $tablet) {
      margin-bottom: 80px;
    }
    @media (max-width: $mobile) {
      margin-bottom: 60px;
    }
    @media (max-width: $mobileSmall) {
      margin-bottom: 50px;
    }

    img {
      width: 80px;
    }
  }
}

.down-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  text-align: center;

  @media (max-width: 760px) {
    flex-direction: column;
    gap: 15px;
  }

  &__copy {
  }

  &__cookie {
    text-decoration: underline !important;

    &:hover {
      text-decoration: none !important;;
    }
  }
}

</style>